import React from 'react';


export default function Privacy(){
  return (
    <div className='legal'>
      <h1>PlantsWetter Privacy Policy</h1>
      <p>Last updated August 13, 2024</p>
      <p>This privacy notice for PlantsWetter (wholly owned by Angels Take Inc.) ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our sites or engage with us in other related ways, including any sales, marketing, or events ("services").</p>
      <p>Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <a href="mailto:hello@plantswetter.com">hello@plantswetter.com</a>.</p>
      
      <h2>TL;DR</h2>
      <p>We hope you will take the time to read our entire privacy policy, but if you do not, this section is a summary of the key points.</p>
      <h3>PlantsWetter is a public platform</h3>
      <p>PlantsWetter is largely public and anyone can see your profile, posts, and comments. Information you share with us in your profile, posts, and comments, should abide by our <a href="/conduct">Code of Conduct</a>. </p>
      <h3>We collect minimal personal information about you</h3>
      <p>We collect minimal information that can be used to identify you by default. If you want to just browse, you don't need an account. If you want to create an account to comment or share your own plant collection, we don't require you to give us your real name. We don't track your precise location. You can share as much or as little about yourself as you want.</p>
      <h3>We only use data to make PlantsWetter a better place</h3>
      <p>Any data we collect is used to provide our Services, which are focused on allowing people to share stories and information about their house plant collections. We don't sell your personal data to third parties, we don’t work with data brokers, and we don’t serve ads on our website. </p>
      <p>We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>
      <h3>All of our users get privacy rights</h3>
      <p>Anyone can request a copy of their data, account deletion, or information about our policies. Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. We will consider and act upon any request in accordance with applicable data protection laws. </p>
      <p>We've tried our best to make this as easy to understand as possible but sometimes privacy policies are still confusing. If you need help understanding this policy or anything about PlantsWetter, please contact us at <a href="mailto:hello@plantswetter.com">hello@plantswetter.com</a>.</p>
      <h3>What it means to be a public platform</h3>
      <p>Most of our content is public and accessible to everyone, even without an account. As a result, anyone who has access to the internet can see public posts, comments, and usernames (collectively, “public content”). You do not need an account to view the public content contributed by other PlantsWetters. You also do not need to contribute public posts or comments on our platform to browse. If you choose to contribute public content, you are directing us to share the information you share with us publicly and freely.  </p>
      <p>When you submit content (e.g., a post or comment) to a public part of the Services, any visitors to and users of our Services will be able to see that content, the username associated with the content, and the date and time you originally submitted the content. That content and information may also be available in search results on Internet search engines like Google, and you will not have control over who can or cannot view it. You should take the public nature of the Services into consideration before posting.</p>
      <p>Some content or data that you share with us or on the PlantsWetter platform will not be made publicly available or licensed, including:</p>
      <ul>
        <li>Deleted posts and comments; </li>
        <li>Non-public account information (e.g., email address etc.); </li>
      </ul>
      <p>Our community members place great trust in us to respect their private, non-public information, and we take our role as stewards of this information seriously. That’s why we never license private data, nor do we otherwise share it without your permission unless legally compelled to do so. </p>
      
      <h2>What Information We Collect</h2>
      <p>We collect two types of information. 1) the information that you provide to us, and 2) information collected automatically.</p>
      
      <h2>Information you provide to us</h2>
      <p>We believe that the best way to protect your privacy is to gather as little information about you as possible, and only to do so when necessary. Unlike some other social media services, you don't need an account to fully access our site. We encourage you to take some time getting to know the community before officially joining. You can do this without submitting any information to us.</p>
      <h3>Account information</h3>
      <p>If you decide to create a PlantsWetter account, your account will have a username, which is public, but it doesn't have to be related to your real name and you can change it whenever you like. You may need to provide a password, depending on whether you register using an email address or Single Sign-On (SSO) through Google. If you choose to log in through Google, we will receive certain profile information about you from Google, including your name, email address, and profile picture. All of those details can be changed by you once logged in and the original records will not be kept. </p>
      <p>As you use PlantsWetter, you may choose to volunteer other information about yourself, such as a bio, gender, age, location, and profile picture. This information is optional and may be removed by you at any time. We also store your user account preferences and settings, but this information is optional and can be changed at any time on your dashboard page.</p>
      <h3>Content you produce</h3>
      <p>We collect the content you submit to the Services. This includes your posts and comments. Your content may include text, links, and images. We do not save unpublished drafts of posts or comments, and you can delete your posts and comments at any time.</p>
      <h3>Actions you take</h3>
      <p>We collect information about the actions you take when using the Services. This includes your interactions with the platform and content, such as hearting and blocking other users or reporting inappropriate content.</p>
      <h3>Other information</h3>
      <p>You may choose to provide other information directly to us. For example, we may collect information when you fill out a form, survey, promotions, or programs, apply for a job, request customer support, or otherwise communicate with us.</p>
      
      <h2>Information we collect automatically</h2>
      <p>Whenever our services are accessed, we collect anonymized and aggregated information about usage such as how many times a database record or image was accessed. It is likely that in the future, as our website grows, we will collect more information from users, such as through the use of Google Analytics or a Facebook pixel, and our privacy policy will be updated at that time.</p>
      
      <h2>How We Use (Process) Information</h2>
      <p>We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests. For example, we process your information in order to:</p>
      <ul>
        <li>Provide, maintain, and improve the Services;</li>
        <li>Personalize services, content, and features that match your preferences and settings;</li>
        <li>Help protect the safety of PlantsWetter and our users, which includes blocking suspected spammers, addressing abuse, and enforcing our User Agreement;</li>
        <li>Research and develop new services;</li>
        <li>Send you technical notices, updates, security alerts, invoices, and other support and administrative messages;</li>
        <li>Provide customer service;</li>
        <li>Communicate with you about products, services, offers, promotions, events, and programs, and provide other news and information we think will be of interest to you. We maintain one list of email addresses that we use for these purposes, which you can unsubscribe from at any time;</li>
        <li>Diagnose problems and/or prevent fraudulent activities;</li>
        <li>To comply with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved. Do not use our services to post picture of illegal plants you may be growing;</li>
        <li>Where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.</li>
      </ul>

      <h2>How We Share Information</h2>
      <p>In addition to the information that is displayed publicly as described above, we may share information in the following ways:</p>
      <ul>
        <li>With our service providers. We may share information with vendors, consultants, and other service providers who need access to such information to carry out work for us. Their use of personal data will be subject to appropriate confidentiality and security measures. A few examples: (i) payment processors who process transactions on our behalf, (ii) cloud providers who host our data and our services, and (iii) third-party ads measurement providers who help us measure the effectiveness of our advertising programs.</li>
        <li>To comply with the law. We may share information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, legal process, or governmental request, including, but not limited to, meeting national security or law enforcement requirements. To the extent the law allows it, we will attempt to provide you with prior notice before disclosing your information in response to such a request.</li>
        <li>To enforce our rights and promote safety and security. We may share information if we believe your actions are inconsistent with our User Agreement, rules, or to protect the rights, property, and safety of the Services, ourselves, and others.</li>
        <li>Aggregated or de-identified information. We may share information about you that has been aggregated or anonymized such that it cannot reasonably be used to identify you. For example, we may show the total number of times a post has been upvoted without identifying who the visitors were.</li>
      </ul>
      
      <h2>How We Protect Your Information</h2>
      <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>
      <h2>our Rights and Choices</h2>
      <p>You have choices about how to protect and limit the collection, use, and sharing of information about you when you use the Services. Depending on where you live, you may also have the right to correction/rectification of your personal information, to opt out of certain advertising practices, or to withdraw consent for processing where you have previously provided consent. Below we explain how to exercise each of these rights. PlantsWetter does not discriminate against users for exercising their rights under data protection laws.</p>
      <h3>Accessing and Changing Your Information</h3>
      <p>You can access your information and change or correct certain information through the Services. See our Help Center page for more information. You can also request a copy of the personal information PlantsWetter maintains about you by following the process described here.</p>
      <h3>Deleting Your Account</h3>
      <p>You may delete your account information at any time from the user preferences page. You can also submit a request to delete the personal information PlantsWetter maintains about you by following the process described in the "Your Rights - Data Subject and Consumer Information Requests" section below. When you delete your account, your profile is no longer visible to other users and disassociated from content you posted under that account. Please note, however, that the posts, comments, and messages you submitted prior to deleting your account will still be visible to others unless you first delete the specific content. After you submit a request to delete your account, it may take up to 90 days for our purge script to complete deletion. We may also retain certain information about you for legitimate business purposes and/or if we believe doing so is in accordance with, or as required by, any applicable law.</p>
      <h3>Controlling the Use of Cookies</h3>
      <p>Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject first- and third-party cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services. For more information on controlling how cookies and similar technologies are used on PlantsWetter, see our Cookie Notice.</p>

      <h3>Controlling Advertising and Analytics</h3>
      <p>Some analytics providers we partner with may provide specific opt-out mechanisms and we may provide, as needed and as available, additional tools and third-party services that allow you to better understand cookies and how you can opt out. For example, you may manage the use and collection of certain information by Google Analytics via the Google Analytics Opt-out Browser Add-on. </p>

      <h3>Do Not Track</h3>
      <p>Most modern web browsers give you the option to send a Do Not Track signal to the sites you visit, indicating that you do not wish to be tracked. However, there is no accepted standard for how a site should respond to this signal, and we do not take any action in response to this signal. Instead, in addition to publicly available third-party tools, we offer you the choices described in this policy to manage the collection and use of information about you.</p>

      <h3>Controlling Promotional Communications</h3>
      <p>You may opt out of receiving some or all categories of promotional communications from us by following the instructions in those communications or by updating your email options in your account preferences here. If you opt out of promotional communications, we may still send you non-promotional communications, such as information about your account or your use of the Services.</p>

      <h3>Controlling Location Information</h3>
      <p>You can control how we use location information for feed and recommendations customization via the Safety and Privacy settings setting in your Account Settings.</p>

      <p>If you have questions or are not able to submit a request to exercise your rights using the mechanisms above, you may also email us at <a href="mailto:hello@plantswetter.com">hello@plantswetter.com</a> from the email address that you have verified with your PlantsWetter account, or submit them here.</p>

      <p>Before we process a request from you about your personal information, we need to verify the request via your access to your PlantsWetter account or to a verified email address associated with your PlantsWetter account. If we deny your request, you may appeal our decision by contacting us at <a href="mailto:hello@plantswetter.com">hello@plantswetter.com</a>.</p>
      <h2>International Data Transfers</h2>
      <p>PlantsWetter, Inc. is based in the United States and we process and store information on servers located in the United States. By accessing or using the Services or otherwise providing information to us, you consent to the processing, transfer, and storage of information in and to the United States, where you may not have the same rights as you do under local law.</p>

      <p>When we transfer the personal data of users in the EEA, UK and/or Switzerland, we rely on the Standard Contractual Clauses approved by the European Commission for such transfers or other transfer mechanisms deemed 'adequate' under applicable laws.</p>
      <h2>Additional Information for EEA and UK Users</h2>

      <p>Users in the EEA and UK have the right to request access to, rectification of, or erasure of their personal data; to data portability in certain circumstances; to request restriction of processing; to object to processing; and to withdraw consent for processing where they have previously provided consent. These rights can be exercised as described in the "Your Rights and Choices" section above. EEA users also have the right to lodge a complaint with their local supervisory authority.</p>

      <p>As required by applicable law, we collect and process information about individuals in the EEA and UK only where we have a legal basis for doing so. Our legal bases depend on the Services you use and how you use them. We process your information on the following legal bases:</p>

      <ul>
      <li>You have consented for us to do so for a specific purpose;</li>
      <li>We need to process the information to provide the Services, including to operate the Services, provide customer support and personalized features and to protect the safety and security of the Services;</li>
      <li>It satisfies a legitimate interest (which is not overridden by your data protection interests), such as preventing fraud, ensuring network and information security, enforcing our rules and policies, protecting our legal rights and interests, research and development, personalizing the Services, and marketing and promoting the Services; or</li>
      <li>We need to process your information to comply with our legal obligations.</li>
      </ul>

      <h2>Additional Information for California Users</h2>
      <p>The California Consumer Privacy Act ("CCPA"), as amended, requires us to provide California residents with some additional information, which we address in this section.</p>

      <p>In the last 12 months, we collected the following categories of personal information from California residents, depending on the Services used:</p>

      <ul>
      <li>Identifiers and account information, like your username, email address, phone number, IP address, and cookie information.</li>
      <li>Commercial information, including information about transactions you undertake with us.</li>
      <li>Internet or other electronic network activity information, such as information about your activity on our Services and limited information about your activity on the services of advertisers who use our advertising technology.</li>
      <li>Your messages with other users (e.g., private messages, chats, and modmail).</li>
      <li>Picture content submitted to PlantsWetter.</li>
      <li>Professional or employment-related information or demographic information, but only if you explicitly provide it to us, such as by applying for a job or filling out a survey.</li>
      </ul>

      <p>You can find more information about (a) what we collect and sources of that information in the "Information We Collect" section of this notice, (b) the business and commercial purposes for collecting that information in the "How We Use Your Information" section, and (c) the categories of third parties with whom we share that information in the "How We Share Information" section.</p>
      <p>If you are a California resident, you have additional rights under the CCPA, including the right to opt out of any sales or sharing of your personal information, to request access to and information about our data practices, and to request deletion or correction of your personal information, as well as the right not to be discriminated against for exercising your privacy rights. PlantsWetter does not "sell" or "share" personal information as those terms are defined under the CCPA. We do not use or disclose sensitive personal information except to provide you the Services or as otherwise permitted by the CCPA.</p>

      <p>You may exercise your rights to access, delete, or correct your personal information as described in the "Your Rights and Choices" section of this notice. When you make a request, we will verify your identity by asking you to sign into your account or if necessary by requesting additional information from you. You may also make a rights request using an authorized agent. If you submit a rights request from an authorized agent who does not provide a valid power of attorney, we may ask the authorized agent to provide proof that you gave the agent signed permission to submit the request to exercise rights on your behalf. In the absence of a valid power of attorney, we may also require you to verify your own identity directly with us or confirm to us that you otherwise provided the authorized agent permission to submit the request. If you have any questions or concerns, you may reach us using the methods described under "Your Rights and Choices" or by emailing us at <a href="mailto:hello@plantswetter.com">hello@plantswetter.com</a>.</p>
      <h2>Data Collection from Minors</h2>
      <p>We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. Children under the age of 13 are not allowed to create an account or otherwise use the Services, even with parent or guardian consent. Additionally, if you are located outside the United States, you must be over the age required by the laws of your country to create an account or otherwise use the Services, or we need to have obtained verifiable consent from your parent or legal guardian.</p>

      <p>If we learn that personal information from users less than 13 years of age,  or users less than 18 years of age without parent/guardian consent, has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:hello@plantswetter.com">hello@plantswetter.com</a>. </p>
      <h2>Changes to This Policy</h2>
      <p>We may change this Privacy Policy from time to time. If we do, we will let you know by revising the date at the top of the policy. If the changes, in our sole discretion, are material, we may also notify you by sending an email to the address associated with your account (if you have chosen to provide an email address and have not unsubscribed from our distribution list) or by otherwise providing notice through our Services. We encourage you to review the Privacy Policy regularly to stay informed about our information practices and the ways you can help protect your privacy. By continuing to use our Services after Privacy Policy changes go into effect, you agree to be bound by the revised policy.</p>

    </div>
  );
} 
