import React, {useState, useEffect} from 'react';
import { collection, query, orderBy, getDocs } from "firebase/firestore";
import { db } from '../firebase'


export default function SearchResults(){
    const border = {borderRadius: "7px 7px 0px 0px"};
    const color = {color: "var(--green)"};

    const journalList = collection(db, "users");
    const [allJournals, setAllJournals] = useState([])
    useEffect(() => {
        let journals = [];
        
        const queryJournals = query(
            journalList,
            orderBy("recentUpdate", "desc"),
        );
        const fetchJournals = async () => {
            let data = await getDocs(queryJournals);
            data.forEach((doc) => {
                journals.push({ID: doc.id, ...doc.data() });
            })
            setAllJournals(journals)
        };
        fetchJournals();
    },[]);

    return (
        <div className="browse">
            <div className='search-page'>
                <div className="search-bar">
                    <h2 style={border}>Browse Journals</h2>
                </div>
                <div className='search-results'>
                    {allJournals.map((journal) =>
                        <div className='search-result'>
                            <a href={`journals/${journal.ID}`}><img
                                src={journal.primaryURL}
                                alt="Link to a user's journal"
                                className='search-result-image'
                                />
                            </a>
                            <div className='search-result-info'>
                                <div className='search-result-name'>
                                    <h3>Plants Wetter </h3>
                                    <h3 style={color}>{journal.displayName}</h3>
                                </div>
                                <div className='search-result-location'>
                                    <h3>{journal.city}, {journal.state}</h3>
                                </div>
                                
                            </div>
                        </div>
                        
                    )}
                </div>
            </div>
        </div>
    )
};
