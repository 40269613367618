import { db } from '../firebase';
import Modal from "./crop/Modal";
import {useAuth} from '../contexts/AuthContext';
import React, {useState, useRef, useEffect} from 'react';
import { doc, getDoc, setDoc } from "firebase/firestore";
import {Link, useNavigate, Navigate} from "react-router-dom";

import LogoutIcon from "./icons/LogoutIcon";
import CameraIcon from "./icons/CameraIcon";
import ProfileIcon from "./icons/ProfileIcon";
import SettingsIcon from "./icons/SettingsIcon";
import LandscapeIcon from "./icons/LandscapeIcon";


export default function Settings(){
    const navigate = useNavigate();
    const {currentUser, logout, updatePassword, updateEmail} = useAuth();
    
    const [error, setError] = useState('');
    const [hidden, setHidden] = useState(true);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);
    
    const emailRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const usernameRef = useRef();
    const passwordRef = useRef();
    const avatarUrl = useRef('');
    const primaryUrl = useRef('');
    const passwordConfirmRef = useRef();
    const loginType = useRef();
    

    async function handleLogout(){
        setError('')

        try {
            await logout()
            navigate('/')
        } catch {
            setError("Somehow failed to log out??")
        }
    }

    async function handlePassUpdate(){
        if (passwordRef.current.value !== passwordConfirmRef.current.value){
            return setError("Whoopsie, your passwords don't match.")
        }

        if (passwordRef.current.value.length < 6){
            return setError("Your passwod needs to have at least six characters.")
        }

        if (passwordRef.current.value){
            try{
                setError('')
                setLoading(true)
                await updatePassword(passwordRef.current.value)
                setError("Password successfully updated.")
            } catch {
                setError("Oops, we couldn't update your password.")
            }
            setLoading(false)
        }
    }

    async function handleEmailUpdate(){
        if (emailRef.current.value !== currentUser.email){
            try{
                setError('')
                setLoading(true) 
                await updateEmail(emailRef.current.value)
                setError("An email with additional instructions has been sent.")
            } catch {
                setError("Oops, we couldn't update your email address.")
            }
            setLoading(false)
        }
    }

    const [cityState, setCityState] = useState('');
    async function handleCityUpdate(){
        if (cityRef.current.value){
            try{
                setError('')
                setLoading(true)
                await setDoc(doc(db, "users", currentUser.uid), {city: `${cityRef.current.value}`}, {merge:true})
                setCityState(cityRef.current.value)
                setError("City updated.")
            } catch {
                setError("Oops, city failed to update.")
            }
            setLoading(false)
        }
    }

    const [stateState, setStateState] = useState('');
    async function handleStateUpdate(){
        if (stateRef.current.value){
            try{
                setError('')
                setLoading(true)
                await setDoc(doc(db, "users", currentUser.uid), {state: `${stateRef.current.value}`}, {merge:true})
                setStateState(stateRef.current.value)
                setError("State code updated.")
            } catch {
                setError("Oops, state code failed to update.")
            }
            setLoading(false)
        }
    }

    const [usernameState, setUsernameState] = useState('')
    async function handleUsernameUpdate(){
        if (usernameRef.current.value){
            try{
                setError('')
                setLoading(true)
                await setDoc(doc(db, "users", currentUser.uid), {displayName: `${usernameRef.current.value}`}, {merge:true})
                setUsernameState(usernameRef.current.value)
                setError("User name updated.")
            } catch {
                setError("Oops, user name failed to update.")
            }
            setLoading(false)
        }
    }

    useEffect(()=> {
        if (!currentUser.uid) {
            return <Navigate to="/signup" />
        }

        const userProfileRef = doc(db, "users", currentUser.uid);
        const getUserProfile = async () => {
            try {
                const data = await getDoc(userProfileRef);
                setCityState(data.data().city);
                setStateState(data.data().state);
                setUsernameState(data.data().displayName)
                setImagesLoaded(true)
                primaryUrl.current = data.data().primaryURL;
                avatarUrl.current = data.data().photoURL;
                loginType.current = data.data().loginType;
            } catch(err){
                console.error(err);
            }
        };
        getUserProfile();
    }, [currentUser.uid]);

    const updateAvatar = (imgSrc) => {
        avatarUrl.current = imgSrc;
    };

    function toggleHidden() {
        hidden===true ? setHidden(false) : setHidden(true)
    }

    return currentUser ? (
        <div className="profile">
            {!imagesLoaded ? <LandscapeIcon width="240px"/> : 
                <img
                src={primaryUrl.current}
                alt="User's collection overview"
                className="primary"
                />
            }

            {!imagesLoaded ? <ProfileIcon width="24px"/> :
                <img
                src={avatarUrl.current}
                alt="User's avatar"
                className="avatar"
                />
            }

            <button
            hidden={hidden}
            className="avatar_button"
            title="Change photo"
            onClick={() => setModalOpen(true)}
            >
            <CameraIcon width="24px"/> Update Profile Pic
            </button>

            {modalOpen && (
                <Modal
                updateAvatar={updateAvatar}
                closeModal={() => setModalOpen(false)}
                modalType = 'profile'
                />
            )}

            <h1>Welcome {usernameState}! </h1>
            {error && <div className="signup-alert-box">{error}</div>}

            <div hidden={hidden}>
                <label htmlFor="username">New user name: </label>
                <input type="username" name="username" id="username" ref={usernameRef}/>
            </div>
            <button hidden={hidden} type="usernameUpdate" onClick={handleUsernameUpdate} disabled={loading}>Update User Name</button>


            {loginType.current==="email" && (
                <div>
                    <div hidden={hidden}>
                        <label htmlFor="password">New password: </label>
                        <input type="password" name="password" id="password" ref={passwordRef}/>
                    </div>
                    <div hidden={hidden}>
                        <label htmlFor="confirm-password">Confirm password</label>
                        <input type="password" name="confirm-password" id="confirm-password" ref={passwordConfirmRef}/>
                    </div>
                    <button hidden={hidden} type="passwordUpdate" onClick={handlePassUpdate} disabled={loading}>Update Password</button>
                </div>
            )}

            <h4>Email: {currentUser.email}</h4>
            <div hidden={hidden}>
                <label htmlFor="email">New email: </label>
                <input type="email" name="email" id="email" ref={emailRef}/>
            </div>
            <button hidden={hidden} type="emailUpdate" onClick={handleEmailUpdate} disabled={loading}>Update Email Address</button>

            <h4>Location: {cityState}, {stateState}</h4>
            <div hidden={hidden}>
                <label htmlFor="city">New city: </label>
                <input type="city" name="city" id="city" ref={cityRef}/>
            </div>
            <button hidden={hidden} type="cityUpdate" onClick={handleCityUpdate} disabled={loading}>Update City</button>

            <div hidden={hidden}>
                <label htmlFor="state">New state: </label>
                <input type="state" name="state" id="state" ref={stateRef}/>
            </div>
            <button hidden={hidden} type="stateUpdate" onClick={handleStateUpdate} disabled={loading}>Update State</button>

            <button onClick={toggleHidden} hidden={!hidden} disabled={loading}><SettingsIcon width="24px"/> Settings</button>
            <button onClick={toggleHidden} hidden={hidden} disabled={loading}><SettingsIcon width="24px"/> Close Settings</button>
            <Link to="/">
                <button onClick={handleLogout} disabled={loading}><LogoutIcon width="24px"/> Log Out</button>
            </Link>
        </div>
    ) : <Navigate to="/signup" />
}